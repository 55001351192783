/** @format */

import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import * as queryString from 'query-string';

import SEO from '../components/SEO';
import styles from '../css/products-css-modules.module.css';
import Banner from '../components/Banner';
import Layout from '../components/layout/Layout';
import Pagination from '../components/Pagination';
import { ProductCard } from '../components/Card';
import { Row, SectionWrapper, SectionInnerWrapper, CommonContentDivider } from '../components/Wrapper';

const itemsPerPage = 16;

const ProductsPage = ({ data }) => {
    const [pageCount, setPageCount] = useState(0);
    const [offset, setOffset] = useState(0);
    const [items, setItems] = useState(null);
    const [category, setCategory] = useState('');

    const getItemsByCategory = (cat) => {
        let itemList = [];

        data.allStrapiProduct.edges.map((x) => {
            if (x.node.category === cat) itemList.push(x.node);
        });

        return itemList;
    };

    const filterByCategory = (cat) => {
        const itemList = getItemsByCategory(cat);
        setItems(itemList);
        setCategory(cat);
        setOffset(0);
        setPageCount(Math.ceil(itemList.length / itemsPerPage));
    };

    useEffect(() => {
        const param = queryString.parse(location.search);
        let initialCategory = param && param.category;
        if (!initialCategory) {
            initialCategory = data.allStrapiProductcategory.edges[0].node.title;
        }
        setCategory(initialCategory);

        const initialItems = getItemsByCategory(initialCategory);
        setItems(initialItems);
        setPageCount(Math.ceil(initialItems.length / itemsPerPage));
    }, []);

    return (
        <Layout>
            <SEO title={`All Ins Agency - All Products`} description={`All Ins Agency`} />
            <Banner
                heading={data.allStrapiBanner.edges[0].node.heading}
                subHeading={data.allStrapiBanner.edges[0].node.subheading}
                trail={data.allStrapiBanner.edges[0].node.name}
                trailLink="/products"
                image={data.site.siteMetadata.apiUrl + data.allStrapiBanner.edges[0].node.fields.imageUrl}
            />
            <SectionWrapper>
                <SectionInnerWrapper>
                    <div className="flex flex-col lg:flex-row mb-8 justify-center">
                        {data.allStrapiProductcategory.edges.map((x, i) => (
                            <Row key={i} className="justify-center md:mx-4">
                                <div
                                    className={
                                        category === x.node.title ? styles.activeFilterSquare : styles.filterSquare
                                    }></div>
                                <button
                                    className={category === x.node.title ? styles.activeFilter : styles.filter}
                                    onClick={() => filterByCategory(x.node.title)}>
                                    {x.node.title} Insurance
                                </button>
                            </Row>
                        ))}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 col-gap-8 row-gap-6">
                        {items &&
                            items.map(
                                (x, i) =>
                                    i >= offset &&
                                    i < offset + itemsPerPage && (
                                        <ProductCard
                                            key={i}
                                            title={x.name}
                                            index={x.id}
                                            iconType={x.icontype}
                                            iconName={x.iconname}
                                        />
                                    )
                            )}
                    </div>
                    <CommonContentDivider></CommonContentDivider>
                    <Pagination pageCount={pageCount} itemsPerPage={itemsPerPage} setOffset={setOffset} />
                </SectionInnerWrapper>
            </SectionWrapper>
        </Layout>
    );
};

export default ProductsPage;

export const productsQuery = graphql`
    query ProductsQuery {
        allStrapiBanner(filter: { page: { eq: "/products" } }, sort: { fields: fields___sortindexnum, order: ASC }) {
            edges {
                node {
                    subheading
                    heading
                    name
                    page
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        allStrapiProductcategory(sort: { fields: fields___sortindexnum, order: ASC }) {
            edges {
                node {
                    fields {
                        sortindexnum
                    }
                    title
                }
            }
        }
        allStrapiProduct(sort: { fields: fields___sortindexnum, order: ASC }) {
            edges {
                node {
                    id
                    name
                    category
                    icontype
                    iconname
                    numofpeople
                    location
                    payment
                    risklevel
                    support
                    motorenquiry
                    showonhomepage
                    body
                    fields {
                        sortindexnum
                    }
                }
            }
        }
        site {
            siteMetadata {
                apiUrl
            }
        }
    }
`;

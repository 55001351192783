/** @format */

import React from 'react';
import { Link } from 'gatsby';
import styled, { keyframes } from 'styled-components';
import { flipInY } from 'react-animations';

import Icon from './Icon';

const Card = ({ className, children }) => (
    <div
        className={`p-12 rounded-lg border border-solid border-gray-200 ${className}`}
        style={{
            boxShadow: '0 10px 28px rgba(0,0,0,.08)',
        }}>
        {children}
    </div>
);

export default Card;

export const TestimonialCard = ({ testimony, name, designation, company, imageUrl }) => {
    return (
        <TestimonialCardWrapper>
            <QuoteWrapper>
                <QuoteIconWrapper>
                    <Icon type="fas" name="quote-left" />
                </QuoteIconWrapper>
                <QuoteContent>{testimony}</QuoteContent>
                <QuoteWrapperTail></QuoteWrapperTail>
            </QuoteWrapper>
            <TestimonialClientWrapper>
                <img
                    src={imageUrl}
                    draggable="false"
                    alt="testimonial"
                    style={{ borderRadius: '50%', width: '44px', height: '44px' }}
                />
                <TestimonialClientDetailsWrapper>
                    <TestimonialClientName>{name}</TestimonialClientName>
                    <TestimonialClientPosition>
                        {designation}
                        {company && ','} {company}
                    </TestimonialClientPosition>
                </TestimonialClientDetailsWrapper>
            </TestimonialClientWrapper>
        </TestimonialCardWrapper>
    );
};

const TestimonialCardWrapper = styled.div`
    padding: 0px 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    draggable: false;
`;

const QuoteWrapper = styled.div`
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 30px 20px 30px 65px;
    position: relative;
    font-family: Raleway;
    font-size: 17px;
    line-height: 27px;
    font-style: italic;
    color: #a6a6a6;
    box-shadow: 0 2px 3px 0 rgba(39, 41, 48, 0.08);
    -moz-box-shadow: 0 2px 3px 0 rgba(39, 41, 48, 0.08);
    -webkit-box-shadow: 0 2px 3px 0 rgba(39, 41, 48, 0.08);
    margin-bottom: 15px;
`;

const QuoteWrapperTail = styled.div`
    content: '';
    border-left: 20px solid transparent;
    border-top: 20px solid transparent;
    border-right: 20px solid #ffffff;
    border-bottom: 20px solid transparent;
    position: absolute;
    bottom: -14px;
    left: 0;
    font-style: normal;
`;

const QuoteContent = styled.p`
    font-family: Raleway;
    font-size: 16px;
    letter-spacing: 0.025em;
    line-height: 1.6;
    font-style: italic;
    margin-bottom: 0px;
    color: #a6a6a6;
    font-weight: 400;
`;

const QuoteIconWrapper = styled.div`
    font-size: 28px;
    color: #666666;
    position: absolute;
    top: 30px;
    left: 20px;
    font-style: normal;
`;

const TestimonialClientWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const TestimonialClientDetailsWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: 13px;
    font-weight: 700;
`;

const TestimonialClientName = styled.span`
    font-family: 'Raleway';
    font-size: 16px;
    color: #373636;
    margin-bottom: 5px;
`;

const TestimonialClientPosition = styled.span`
    font-family: 'Ubuntu';
    font-size: 13px;
    line-height: 18px;
    color: #368aea;
`;
//h-56 md:h-64
export const AwardCard = ({ logoUrl }) => {
    return (
        <div className="h-full overflow-hidden relative flex justify-center items-center mx-2">
            <img src={logoUrl} draggable="false" alt="award" width="100%" />
        </div>
    );
};

export const ClientCard = ({ logoUrl }) => {
    return (
        <ClientCardWrapper>
            <ClientLogoWrapper>
                <ClientLogo src={logoUrl} draggable="false" alt="client" width="170" height="68" />
            </ClientLogoWrapper>
        </ClientCardWrapper>
    );
};

const ClientCardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ClientLogoWrapper = styled.div`
    padding: 24px 12px;
    background: #fff;
    border-right: 1px Solid #e5e5e5;
`;

const ClientLogo = styled.img`
    opacity: 0.75;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &:hover {
        opacity: 1;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
`;

export const ProductCard = ({ index, title, iconType, iconName }) => {
    const flipAnimation = keyframes`${flipInY}`;

    const ProductCardWrapper = styled.div`
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px 15px;
        background-color: #368aea;
        animation: 1s ${flipAnimation};
        color: #fefefe;
        &:hover {
            background-color: #004bc2;
            color: #f5f5f5;
            -webkit-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }
    `;

    const ProductIconWrapper = styled.div`
        font-size: 18px;
    `;

    const ProductTitle = styled.h2`
        font-size: 16px;
        line-height: 28px;
        font-weight: 700;
        font-family: 'Raleway';
        text-align: center;
        margin-top: 10px;
    `;

    return (
        <Link to={`/product?id=${index}`}>
            <ProductCardWrapper>
                <ProductIconWrapper>
                    <Icon type={iconType} name={iconName} size="4x" />
                </ProductIconWrapper>
                <ProductTitle>{title}</ProductTitle>
            </ProductCardWrapper>
        </Link>
    );
};

export const ProductImageCard = ({ index, title, image }) => {
    const flipAnimation = keyframes`${flipInY}`;

    const ProductCardWrapper = styled.div`
        position: relative;
        overflow: hidden;
        animation: 1s ${flipAnimation};
    `;

    const ProductContentWrapper = styled.div`
        position: absolute;
        top: 0;
        padding: 10px;
        width: 100%;
        text-align: center;
        background: rgba(64, 64, 64, 0.95);
        height: 100%;
        opacity: 0;
        &:hover {
            opacity: 0.95;
            -webkit-transition: all 0.3s ease-in-out 0s;
            -o-transition: all 0.3s ease-in-out 0s;
            transition: all 0.3s ease-in-out 0s;
        }
    `;

    const ProductItemWrapper = styled.div`
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    `;

    const ProductTitle = styled.h2`
        font-size: 18px;
        line-height: 28px;
        color: #ffffff;
        font-weight: 700;
        margin: 0px 0 20px 0;
        text-align: center;
        clear: both;
        background-color: rgba(255, 255, 255, 0);
        position: relative;
        font-family: 'Raleway';
        text-transform: uppercase;
    `;

    return (
        <div>
            <Link to={`/product?id=${index}`}>
                <ProductCardWrapper>
                    <img src={image} alt="Motor Insurance" width="100%" />
                    <ProductContentWrapper>
                        <ProductItemWrapper>
                            <ProductTitle>{title}</ProductTitle>
                        </ProductItemWrapper>
                    </ProductContentWrapper>
                </ProductCardWrapper>
            </Link>
        </div>
    );
};
